var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"wrapper-1 bg-white",attrs:{"persistent":"","width":"450","content-class":_vm.isDarkMode ? 'dark-mode' : ''},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"wrapper-1"},[_c('v-card-title',{staticClass:"bg-grey fs-1-5r"},[_c('span',{staticClass:"ma-auto",attrs:{"tabindex":"0"}},[_vm._v(_vm._s(_vm.$t('Results'))+" "+_vm._s(_vm.loggedUser.role === 'Individual' ? 'And Downloading' : ''))])]),_c('v-card-text',{staticClass:"pa-1 session-report-wrapper pa-2 fs-1-25r",class:[
        _vm.accessibility.isStrongColors && 'strong-colors',
        _vm.language === 'he' ? 'dir-rtl' : 'dir-ltr',
      ],attrs:{"tabindex":"0"}},[_c('div',{staticClass:"fill-height d-flex flex-column overflow-auto"},[_c('span',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Started'))+":")]),_vm._v(" "+_vm._s(_vm.statusResults.session.startTime))]),(_vm.statusResults.session.duration)?_c('span',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Duration'))+":")]),_vm._v(" "+_vm._s(_vm._f("parseTime")(_vm.statusResults.session.duration))+" (mm:ss)")]):_vm._e(),_c('span',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Size'))+":")]),_vm._v(" "+_vm._s(_vm._f("bytesToSize")(_vm.statusResults.session.freezedTotalSize)))]),(
            _vm.loggedUser.organizationId !== 0 &&
            _vm.statusResults.session.selectedRoute?.name
          )?_c('span',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Route'))+":")]),_vm._v(" "+_vm._s(_vm.statusResults.session.selectedRoute?.name))]):_vm._e(),_vm._l((_vm.statusResults.session.interfaceDynamicFields.filter(el => el.value)),function(item){return _c('div',{staticClass:"my-1"},[(_vm.loggedUser.role !== 'Individual')?_c('span',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(item.textToDisplay)+":")]),_vm._v(" "+_vm._s(item.value)+" ")]):_vm._e()])}),(
            _vm.loggedUser.organizationId !== 0 &&
            _vm.statusResults.session.selectedUsers?.length > 0
          )?_c('div',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Sent to registered accounts'))+":")]),_c('ul',_vm._l((_vm.statusResults.session.selectedUsers),function(user){return _c('li',[_vm._v(" "+_vm._s(user)+" ")])}),0)]):_vm._e(),(_vm.isShowSentToCasuals)?_c('div',{staticClass:"my-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('Sent to guests'))+":")]),_c('ul',_vm._l((_vm.statusResults.session.selectedCasualUsers),function(user){return _c('li',[_vm._v(" "+_vm._s(user)+" ")])}),0)]):_vm._e(),(_vm.statusResults.intact && _vm.loggedUser.isAllowReport)?[_c('v-divider',{staticClass:"my-1"}),_c('div',{staticClass:"my-2 d-flex justify-space-between"},[_c('div',[_c('div',{staticClass:"mb-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('Transference Report'))+" ")]),_c('Summary',{attrs:{"statusResults":_vm.statusResults}})],1),_c('v-divider',{staticClass:"mt-1",attrs:{"vertical":""}}),_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('Button',{staticClass:"my-1",attrs:{"clickAction":() => (_vm.isShowFullReport = !_vm.isShowFullReport),"text":`${_vm.isShowFullReport ? _vm.$t('Hide') : _vm.$t('Show')} ${_vm.$t(
                  'Filtering Report'
                )}`,"width":"200","btnType":"grey","btnSize":"small","height":"25"}}),_c('Button',{staticClass:"my-1",attrs:{"clickAction":_vm.downloadReportClicked,"text":_vm.$t('Download Filtering Report'),"width":"200","btnType":"grey","height":"25","btnSize":"small"}})],1)],1)]:_vm._e(),(_vm.isShowFullReport)?_c('div',{staticClass:"wrapper-2 ma-2",staticStyle:{"max-height":"600px","overflow":"auto","direction":"ltr"},domProps:{"innerHTML":_vm._s(_vm.statusResults.session.htmlReport.summary)}}):_vm._e()],2)]),_c('v-divider',{staticClass:"my-1"}),_c('div',{staticClass:"d-flex pa-2 justify-space-between fill-width"},[(_vm.loggedUser.role === 'Individual')?_c('Button',{staticClass:"mx-2",attrs:{"clickAction":_vm.downloadIndividualFiles,"text":"Download Filtered Files"}}):_c('div'),_c('Button',{staticClass:"mx-2",attrs:{"clickAction":_vm.restartSessionClicked,"text":_vm.$t('Close'),"btnType":"grey","width":"100"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }