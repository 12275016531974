<template>
  <div v-if="profile.role !== 'SuperAdmin'" class="mb-4">
    <h4
      class="font-weight-light mt-2"
      :class="[actionType === 'add' && 'required-field']"
    >
      License
    </h4>

    <!--RADIOS FOR LICENSES-->
    <div class="d-flex flex-wrap mb-2">
      <v-radio-group
        v-model="profile.license"
        hide-details
        row
        class="my-0 mx-2"
      >
        <!--WEBSITE LICESE-->
        <v-radio
          hide-details
          value="Website"
          :disabled="isLicenseDisabled('Website')"
          :class="[isLicenseDisabled('Website') && 'disabled']"
        >
          <template slot="label">
            Website
            <span class="mx-1 f-12">
              {{ getOrgLicenseNumber('Website') }}
            </span>
          </template>
        </v-radio>

        <!--SFTP LICENSE-->
        <v-radio
          v-if="profile.role !== 'Individual'"
          hide-details
          value="SFTP"
          :disabled="isLicenseDisabled('SFTP')"
          :class="[isLicenseDisabled('SFTP') && 'disabled']"
        >
          <template slot="label">
            SFTP
            <span class="mx-1 f-12">
              {{ getOrgLicenseNumber('SFTP') }}
            </span>
          </template>
        </v-radio>

        <!--API LICENSE-->
        <v-radio
          v-if="profile.role !== 'Individual'"
          hide-details
          value="API"
          :disabled="isLicenseDisabled('API')"
          :class="[isLicenseDisabled('API') && 'disabled']"
        >
          <template slot="label">
            API
            <span class="mx-1 f-12">
              {{ getOrgLicenseNumber('API') }}
            </span>
          </template>
        </v-radio>

        <!-- NO LICENSE -->
        <v-radio
          v-if="profile.role === 'Admin'"
          hide-details
          value=""
          label="None"
          :disabled="isLicenseDisabled('')"
        />
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Licenses',
  props: {
    accountType: String,
    profile: Object,
    freezedProfile: Object,
    actionType: String,
  },
  computed: {
    ...mapGetters(['currentOrganization']),
  },
  methods: {
    getOrgLicenseNumber(license) {
      if (this.accountType === 'Individual') return
      if (!this.profile.organizationId) return
      if (!this.currentOrganization.allowedLicenses) return
      const { allowedLicenses } = this.currentOrganization

      const foundLicense = allowedLicenses.find(el => el.type === license)
      if (foundLicense)
        return `(${foundLicense.numOfInUse}/${foundLicense.numOfAllowed})`
      return '(0/0)'
    },

    isLicenseDisabled(licenseName) {
      if (this.accountType === 'Individual') return true
      else if (this.actionType === 'edit') return true
      else if (this.profile.status !== 0) return true
      else if (!this.profile.organizationId) return true
      else if (this.currentOrganization.status !== 0) return true
      else if (!this.currentOrganization.allowedLicenses) return true
      const { allowedLicenses } = this.currentOrganization
      const license = allowedLicenses.find(el => el.type === licenseName)
      //if there is no licence
      if (!licenseName) return false
      if (!license) return true


      //if the user have the license and can to remove it
      if (this.profile?.license === license.type) return false

      //if the user doesnt have the license and the license changed
      if (
        license.numOfInUse + 1 > license.numOfAllowed &&
        this.freezedProfile.license === this.profile.license
      ) {
        return true
      }
    },
    isShowLicense(licence) {
      if (licence === 'SFTP' || licence === 'API') {
        if (this.profile.role === 'Individual') {
          return false
        }
      }
      return true
    },
  },
}
</script>

<style scoped src="./Licenses.css"></style>
