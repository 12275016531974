<template>
  <div class="wrapper-1 casual-tree-wrapper">
    <v-treeview
      :items="items"
      dense
      class="fill-width pa-1"
    >
      <template v-slot:prepend="{ item, open }">
        <v-icon v-if="item.children">
          {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
        </v-icon>
        <b
          v-else="item.status"
          class="img-file"
          aria-label="file"
          :class="parseIcon(item.name)"
        />
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { parseIcon } from '@/utils'

export default {
  name: 'CasualDownloadFilesTree',
  props: {
    items: Array,
  },
  methods: {
    parseIcon,
  },
}
</script>

<style scoped lang="scss">
.casual-tree-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px;
  max-height: 60vh;
  overflow: auto;
}
</style>
