import Axios from 'axios'
import store from './store'
import router from './router'
import { i18n } from './main'
import { convertToLocalTime, extractAttemptsLeft } from './utils'

export const hostList = {
  devStaging: 'https://www.cyber-leader.com',
  testStaging: 'https://www.bestcdr.com',
  prodStaging: 'https://www.dotengines.com',
  davidLocal: 'http://localhost:8080',
  relative: '',
}

export const selectedHost = getSelectedHost()

function getSelectedHost() {
  const { host } = window.location
  if (host === 'localhost:8081')
    return hostList.davidLocal //change this if you want to use the local server
  else if (host === 'localhost:8080') return hostList.testStaging
  else if (host.includes('cyber-leader.com')) return hostList.relative
  else if (host.includes('bestcdr.com')) return hostList.relative
  else if (host.includes('dotengines.com')) return hostList.relative
}

export const api = Axios.create({
  baseURL: `${selectedHost}/api`,
  validateStatus: status => true,
  timeout: 360000,
})
api.interceptors.response.use(
  async response => {
    //canceling the error message for the 404 if the user doesn't have any files to download
    let addition = ''
    store.commit('SET_IS_SERVER_ERROR_CLOSE_BTN_HIDDEN', false)
    if (
      response.status === 404 &&
      response.request.responseURL.includes('/filter/directory')
    )
      return response
    if (response.status >= 400) {
      let title = ''
      let message = decodeURIComponent(response.headers['reason-phrase'])
      if (response.status === 401) {
        if (response.request.responseURL.endsWith('/casual-sending-details')) {
          store.commit('SET_SERVER_ERROR_TITLE', i18n.t('Alert'))
          store.commit('SET_IS_SERVER_ERROR_CLOSE_BTN_HIDDEN', true)
          store.commit('SET_SERVER_ERROR_NOTIFICATION', i18n.t('Link expired'))
        }

        if (message.includes('Wrong password')) {
          const attemptsLeft = extractAttemptsLeft(message)
          addition = ': ' + attemptsLeft + '.'
          message = 'Wrong password. Attempts left'
        } else if (message === 'User is temporarily blocked') {
          addition = ' ' + convertToLocalTime(response.data.unblockingDate)

          message = i18n.t(
            'This user is under temporary block. The block will be lifted at'
          )
        } else if (message === "User didn't complete registration") {
          message = i18n.t(
            'Your registration is incomplete. Please complete it by setting a password using the registration email you received.'
          )
        } else if (message === 'The link you are trying to use has expired.') {
          message = i18n.t('The link you are trying to use has expired.')
        } else if (
          message ===
          'The request to renew the password has expired. Please send a new request.'
        ) {
          message = i18n.t(
            'The request to renew the password has expired. Please send a new request.'
          )
        } else if (
          message != 'Username or password is incorrect' &&
          !message.startsWith('Data size exceeded maximum data allowed') &&
          !message.startsWith('data size exceeded maximum data allowed')
        ) {
          sessionStorage.removeItem('user')
          sessionStorage.removeItem('token')
          router.push('/')
          return
        }
      }
     
      
      // if the user is blocked, show a different message

      if (title) {
        store.commit(
          'SET_SERVER_ERROR_TITLE',
          title
        )
      }

      if (message) {
        store.commit(
          'SET_SERVER_ERROR_NOTIFICATION',
          i18n.t(message) + addition
        )
      }
    }
    return response
  },
  error => {
    // whatever you want to do with the error
    throw error
  }
)
