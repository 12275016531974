import { render, staticRenderFns } from "./QuestionsAndAnswers.vue?vue&type=template&id=d89ed4ca&scoped=true&"
import script from "./QuestionsAndAnswers.vue?vue&type=script&lang=js&"
export * from "./QuestionsAndAnswers.vue?vue&type=script&lang=js&"
import style0 from "./QuestionsAndAnswers.css?vue&type=style&index=0&id=d89ed4ca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d89ed4ca",
  null
  
)

export default component.exports