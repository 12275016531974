<template>
  <div class="wrapper-1 pa-2 d-flex align-center h-50 bg-white fill-width">
    <v-icon
    v-if="shownDirectory.path.length"
      @click="backButtonClicked"
      aria-label="Back one folder"
      large
      class="w-40"
      >mdi-arrow-{{
        language === 'he' ? 'right' : 'left'
      }}-circle-outline</v-icon
    >
    <DirectoryPath
      :shownDirectory="shownDirectory"
      :changeShownDirClicked="changeShownDirClicked"
    />
    <TableActions
      :setDirSearch="setDirSearch"
      :shownDirectory="shownDirectory"
      :changeShownDirClicked="changeShownDirClicked"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import DirectoryPath from '@/components/WebsiteInterface/CommonComponents/DirectoryPath/DirectoryPath.vue'

//Children
import TableActions from './Children/TableActions/TableActions.vue'

export default {
  name: 'DirectoryHeader',
  props: {
    shownDirectory: Object,
    changeShownDirClicked: Function,
    setDirSearch: Function,
  },
  components: { DirectoryPath, TableActions },
  computed: {
    ...mapGetters(['language']),
  },
  methods: {
    backButtonClicked() {
      if (this.shownDirectory.name === 'All files') {
        return this.changeShownDirClicked(null, 'backButtonFromAllFiles')
      }
      this.changeShownDirClicked(null, 'backButton')
    },
  },
}
</script>
