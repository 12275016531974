<template>
  <div class="session-history-wrapper">
    <!--BACK BUTTON-->
    <div class="d-flex justify-end">

      <Button
      tabindex="0"
      btnType="grey"
      class="mx-2 mt-2"
      :class="[language === 'he' ? 'float-left' : 'float-right']"
      :text="$t('Cancel')"
      :clickAction="() => $router.push(cameFrom)"
      />
    </div>
    <div class="pos-relative remaining-space">
      <!--HEADER-->
      <div class="wrapper-1 pa-2 mb-2 mx-1 bg-white ">
        <!--HEADER BIG SCREEN-->
        <SessionHistorySearchHeader
          v-if="$vuetify.breakpoint.width > 782"
          :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
          :searchHistory="searchHistory"
          :search="search"
        />

        <!--HEADER MOBILE SCREEN-->
        <div v-else class="d-flex flex-column align-cente">
          <Button
            btnType="grey"
            :clickAction="() => (isShowSearchMobile = !isShowSearchMobile)"
            :text="isShowSearchMobile ? $t('Close Search') : $t('Show Search')"
            :icon="isShowSearchMobile ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            class="mx-auto mt-2"
          />
          <SessionHistorySearchHeader
            v-if="isShowSearchMobile"
            :searchHistory="searchHistory"
            :search="search"
            type="mobile"
          />
        </div>
      </div>

      <!-- <div class="h-110"></div> -->

      <!--TABLE DESKTOP-->

      <Table
      style="width: 600px;"
        v-if="$vuetify.breakpoint.width > 782"
        :headers="computedSessionHistoryHeaders"
        :items="sessions"
        aria-label="Session history table"
        tabindex="0"
        :options="options"
        :rowClickAction="item => (shownSession = item)"
        type="sessions history"
      />

      <!--LIST MOBILE-->
      <div v-else class="overflow-auto sessions-height">
        <SingleSessionCard
          v-for="session of sessions"
          :session="session"
          :key="session.sessionId"
          :setShownSession="v => (shownSession = v)"
          :search="search"
        />
      </div>

      <!--DIALOG TO SHOW THE OPENED SESSION-->
      <ShownSessionDialog
        v-if="shownSession"
        :closeShownSession="() => (shownSession = null)"
        :shownSession="shownSession"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  generateLocalDate,
  generateLocalDateFromUTC0,
  generateNicerLocalDateFromUTC0,
  getUTCdateFromLocalDateDavid,
} from '@/utils'
import { api } from '@/config'
import dayjs from 'dayjs'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import Table from '@/components/BaseComponents/Table/Table.vue'

//Childrens
import SingleSessionCard from './Children/SingleSessionCard/SingleSessionCard.vue'
import ShownSessionDialog from './Children/ShownSessionDialog/ShownSessionDialog.vue'
import SessionHistorySearchHeader from './Children/SessionHistorySearchHeader/SessionHistorySearchHeader.vue'

//Component files
import { sessionHistoryHeaders, summaryBuilder } from './SessionHistory'

export default {
  name: 'SessionHistory',
  data() {
    return {
      summaryBuilder,
      isShowSearchMobile: true,
      shownSession: null,
      sessions: [],
      sessionHistoryHeaders,
      search: {
        startDate: null,
        endDate: null,
        direction: 0, // 0 - sent , 1 - received
        isCasuals: false,
      },
      currentDirection: 0,
      options: {
        sortBy: ['startDate'],
        sortDesc: [true],
        rowsPerPage: 10,
      },
    }
  },

  components: {
    ShownSessionDialog,
    Button,
    Table,
    SessionHistorySearchHeader,
    SingleSessionCard,
  },
  props: {
    cameFrom: String,
  },

  watch: {
    search: {
      handler: function (n) {
        //restart the session when the serch ends
        // if (n) this.sessions = []
      },

      deep: true,
    },
  },

  filters: {
    generateLocalDateFromUTC0,
  },
  computed: {
    ...mapGetters([
      'loggedUser', 
      'language', 
      // 'interactiveInterfaceFields'
    ]),
    computedSessionHistoryHeaders() {
      const headersClone = JSON.parse(
        JSON.stringify(sessionHistoryHeaders(this))
      )

      //if the direction is sent
      if (this.currentDirection === 0) {
        headersClone.unshift({
          text: this.$t('Start Time'),
          value: 'startTime',
          align: 'center',
          width: '150px',
          class: `bg-table-heading ${
            this.language === 'he' ? 'table-header-last' : 'table-header-first'
          }`,
        })
      }
      //if the direction is Received
      else {
        headersClone.unshift({
          text: this.$t('Received Time'),
          value: 'endTime',
          align: 'center',
          width: '150px',
          class: `bg-table-heading ${
            this.language === 'he' ? 'table-header-last' : 'table-header-first'
          }`,
        })
      }

      //if the user cannot see report
      if (!this.loggedUser.isAllowReport)
        return headersClone.filter(el => el.text !== 'Report Summary')

      return headersClone
    },
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    async searchHistory() {
      try {
        this.SET_PROCESSING(true)
        this.isShowSearchMobile = false
        //make the date strings into utc+0 number
        const func = str => {
          const lastHyphenIndex = str.lastIndexOf('-')

          const n =
            str.substring(0, lastHyphenIndex) +
            ':' +
            str.substring(lastHyphenIndex + 1).replace('-', ':')

          return getUTCdateFromLocalDateDavid(n)
        }

        const payload = {
          ...this.search,
          startDate: func(this.search.startDate),
          endDate: func(this.search.endDate),
        }

        const res = await api.post('/users/sessions', payload)
        if (res.status !== 200) throw Error
        this.sessions = res.data.map(ses => {
          console.log(ses.startTime)
          const startTime = generateLocalDate(ses.startTime)
          console.log(startTime)
          const endTime = generateLocalDate(ses.endTime)
          return { ...ses, startTime, endTime }
        })
        this.currentDirection = this.search.direction
      } catch (e) {
        console.log(e)
      } finally {
        const tableHead = document.querySelector('thead')
        const thArr = document.querySelectorAll('th')

        if (tableHead) tableHead.role = 'columnheader'

        thArr.forEach(el => {
          el.tabIndex = 0
        })
        this.SET_PROCESSING(false)
      }
    },
  },

  async created() {
    this.search.startDate = dayjs().add(-7, 'day').format('YYYY-MM-DD HH-mm')
    this.search.endDate = dayjs().format('YYYY-MM-DD HH-mm')
    console.log(dayjs().add(-7, 'day').format('YYYY-MM-DD HH-mm'))
    await this.searchHistory()
  },
}
</script>

<style scoped src="./SessionsHistory.css"></style>
